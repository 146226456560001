import React from 'react';
import Layout from "../components/layout";
import Block from "../components/Block";
import {OutboundLink} from 'gatsby-plugin-google-analytics';
import Component from "../components/Component";
const Cookies = () => (
  <Layout pageTitle="Cookie policy">
    <div className="page-content">
      <Block>
        <Component>
          <h1>Cookie policy</h1>
          <p>A cookie is a small piece of information that is placed on your computer, tablet or phone when you visit certain websites, including this one. These cookies allow us to distinguish you from other users - which makes the site work properly - and to monitor the health of the site. Find out more about cookies on the <OutboundLink target="_blank" href='https://ico.org.uk/for-the-public/online/cookies/'>ICO website</OutboundLink>.</p>
          <p>We use the following types of cookie:</p>
          <ul>
          <li>Session cookies - these are deleted when you finish browsing our website</li>
          <li>Persistent cookies - these are stored on your computer after you have finished browsing our website so that we can recall your preferences the next time you visit.</li>
          </ul>
          <p>Cookies used for analytics and performance monitoring are a mixture of session and persistent cookies, but they do not contain any personal information.</p>
          <p>If you continue to use our site, we assume that you are happy to receive all its cookies. If not, <a href="javascript:window['ga-disable-UA-124607298-3'] = true;">click here to opt out of all website cookies</a>.</p>
        </Component>
      </Block>
    </div>
  </Layout>
)
export default Cookies;
